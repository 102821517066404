import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import './App.css'; // Adicione um arquivo CSS para estilizar o layout
import logo from './logo.png'; // Importa a logo

const defaultRoutes = [
  { id: 1, name: 'São Paulo - Rio de Janeiro', origin: { lat: -23.55052, lng: -46.633308 }, destination: { lat: -22.906847, lng: -43.172896 } },
  { id: 2, name: 'Belo Horizonte - Salvador', origin: { lat: -19.9166813, lng: -43.9344931 }, destination: { lat: -12.9714, lng: -38.5014 } },
  { id: 3, name: 'Curitiba - Porto Alegre', origin: { lat: -25.4284, lng: -49.2733 }, destination: { lat: -30.0346, lng: -51.2177 } },
  { id: 4, name: 'Fortaleza - Recife', origin: { lat: -3.71722, lng: -38.5434 }, destination: { lat: -8.0476, lng: -34.877 } },
  { id: 5, name: 'Manaus - Belém', origin: { lat: -3.11903, lng: -60.02173 }, destination: { lat: -1.45502, lng: -48.50237 } },
  { id: 6, name: 'Brasília - Goiânia', origin: { lat: -15.7942, lng: -47.8822 }, destination: { lat: -16.6869, lng: -49.2643 } },
  { id: 7, name: 'Florianópolis - Porto Alegre', origin: { lat: -27.5954, lng: -48.548 }, destination: { lat: -30.0346, lng: -51.2177 } },
  { id: 8, name: 'São Paulo - Curitiba', origin: { lat: -23.55052, lng: -46.633308 }, destination: { lat: -25.4284, lng: -49.2733 } },
  { id: 9, name: 'Rio de Janeiro - Belo Horizonte', origin: { lat: -22.9068, lng: -43.1729 }, destination: { lat: -19.9167, lng: -43.9345 } },
  { id: 10, name: 'Salvador - Recife', origin: { lat: -12.9714, lng: -38.5014 }, destination: { lat: -8.0476, lng: -34.877 } },
  { id: 11, name: 'Brasília - Salvador', origin: { lat: -15.7942, lng: -47.8822 }, destination: { lat: -12.9714, lng: -38.5014 } },
  { id: 12, name: 'Porto Alegre - Buenos Aires', origin: { lat: -30.0346, lng: -51.2177 }, destination: { lat: -34.6037, lng: -58.3816 } },
  { id: 13, name: 'Recife - Fortaleza', origin: { lat: -8.0476, lng: -34.877 }, destination: { lat: -3.71722, lng: -38.5434 } },
  { id: 14, name: 'Curitiba - São Paulo', origin: { lat: -25.4284, lng: -49.2733 }, destination: { lat: -23.55052, lng: -46.633308 } },
  { id: 15, name: 'Manaus - Boa Vista', origin: { lat: -3.11903, lng: -60.02173 }, destination: { lat: 2.8235, lng: -60.6753 } },
];

const App = () => {
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);

  const handleRouteClick = (route) => {
    setSelectedRoute(route);
  };

  const renderPolylines = (map, maps, route) => {
    if (!route || !map || !maps) return;

    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    const request = {
      origin: route.origin,
      destination: route.destination,
      travelMode: maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      if (status === maps.DirectionsStatus.OK) {
        directionsRenderer.setDirections(result);
      } else {
        console.error(`error fetching directions ${result}`);
      }
    });
  };

  const handleApiLoaded = ({ map, maps }) => {
    setMapInstance(map);
    setMapsInstance(maps);
  };

  React.useEffect(() => {
    if (selectedRoute && mapInstance && mapsInstance) {
      renderPolylines(mapInstance, mapsInstance, selectedRoute);
    }
  }, [selectedRoute, mapInstance, mapsInstance]);

  return (
    <div className="app-container">
      {/* Barra lateral para exibir as rotas */}
      <div className="sidebar">
        <img src={logo} alt="Logo" className="logo" />
        <h2>Rotas Atendidas</h2>
        <ul>
          {defaultRoutes.map((route) => (
            <li key={route.id} onClick={() => handleRouteClick(route)}>
              {route.name}
            </li>
          ))}
        </ul>
      </div>

      {/* Mapa Google */}
      <div className="map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyASbMDNaVTFAv9Lvgrwec7yXRmiBGG2FJM' }}
          defaultCenter={{ lat: -15.7942, lng: -47.8822 }} // Ponto inicial do mapa
          defaultZoom={5}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        />
      </div>
    </div>
  );
};

export default App;